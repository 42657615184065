import React, { useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { customStyles } from "./SelectStyles";
import {
  BaseUrl,
  getBanksbyCountry,
  getRecipientsbyUserId,
  recipientDetails,
} from "../../constants/constantRoute";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../contexts/UserContext";

import { resolveBankAccountName } from "../../api/bank";

const EditRecipient = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const goBack = () => {
    navigate(-1);
  };

  const [bankOptions, setBankOptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${getBanksbyCountry}`)
      .then((res) => {
        const sortedOptions = res.data
          .map((bank) => ({
            value: bank.id,
            label: bank.fullName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)); // Sort the options alphabetically by label

        setBankOptions(sortedOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formData, setFormData] = useState({
    recipientBank: "",
    recipientAccountNumber: "",
    recipientAccountName: "",
    bankDetailId: "",
    recipientEmail: "",
  });

  useEffect(() => {
    if (formData.recipientAccountNumber.length !== 10) return;
    _resoveBankAccountName();
  }, [formData.recipientAccountNumber]);

  async function _resoveBankAccountName() {
    try {
      setLoading(true)
      const res = await resolveBankAccountName(
        formData.recipientAccountNumber,
        formData.recipientBank.value
      );
      setLoading(false)
      setFormData({ ...formData, recipientAccountName: res.data.account_name });
    } catch (error) {
      console.log(error);
      setLoading(false)
      setFormData({ ...formData, recipientAccountName: "" });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAccountNumberChange = (e) => {
    const { name, value } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    axios
      .get(`${BaseUrl}/${getRecipientsbyUserId}${userId}`)
      .then((res) => {
        const recipients = res.data;
        console.log(recipients);
        const recipient = recipients.find((r) => r.id.toString() === id);
        if (!recipient) {
          return;
        }
        setFormData({
          recipientBank: {
            label: recipient.bank.fullName,
            value: recipient.bank.id,
          },
          recipientEmail: recipient.email,
          bankDetailId: recipient.id,
          recipientAccountNumber: recipient.accountNumber,
          recipientAccountName: recipient.fullName,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId, id]);

  const handleEditRecipientForm = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormError(true);
    } else {
      setFormError(false);
      try {
        setLoading(true);
        const res = await axios.patch(`${BaseUrl}${recipientDetails}`, {
          bankDetailId: formData.bankDetailId,
          userId: userId,
          accountNumber: formData.recipientAccountNumber,
          bankId: formData.recipientBank.value,
          fullName: formData.recipientAccountName,
          email: formData.recipientEmail,
        });
        setLoading(false);

        toast.success("Saved Successfully!", {
          autoClose: 3000,
        });
        setTimeout(() => {
          navigate("/dashboard/recipients");
        }, 2000);
        console.log(res.data);
        console.log("Checked!!!");
      } catch (error) {
        setLoading(false);
        console.error("Error", error);
      }
    }
    setValidated(true);
  };

  return (
    <>
      <div className="add-recipient">
        <div className="verify-account container d-flex aligns-items-center flex-column mt-5">
          <Row className="justify-content-center align-items-center">
            <Col sm={3}>
              <button
                className="go-back btn"
                onClick={() => {
                  goBack();
                }}
              >
                <i class="bi bi-arrow-left"></i> back
              </button>
            </Col>
            <Col sm={9}>
              <h6>Edit recipient</h6>
              <p>Create recipient Details</p>
            </Col>
          </Row>

          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <Form
                className="mt-5"
                noValidate
                validated={validated}
                onSubmit={handleEditRecipientForm}
              >
                <Form.Group className="mb-5">
                  <Form.Label>Select Bank</Form.Label>
                  <Select
                    options={bankOptions}
                    name="recipientBank"
                    placeholder={formData.recipientBank}
                    blurInputOnSelect="true"
                    styles={customStyles}
                    value={formData.recipientBank}
                    onChange={(value) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        recipientBank: value,
                      }))
                    }
                  />
                  <p
                    className={
                      !formData.recipientBank && formError
                        ? "select-form-message"
                        : "select-form-message-none"
                    }
                  >
                    Please select recipient's bank
                  </p>
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="012304878"
                    name="recipientAccountNumber"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    maxLength="10"
                    minLength="10"
                    required
                    value={formData.recipientAccountNumber}
                    onChange={handleAccountNumberChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid recipient account number.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John Doe"
                    name="recipientAccountName"
                    required
                    value={formData.recipientAccountName}
                    onChange={handleInputChange}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter recipient name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="johndoe@gmail.com"
                    name="recipientEmail"
                    required
                    value={formData.recipientEmail}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter recipient Email.
                  </Form.Control.Feedback>
                </Form.Group>

                <button
                  disabled={loading}
                  className="add-recipient-btn btn mx-3"
                >
                  {loading ? "Loading..." : "Continue"}
                </button>
              </Form>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default EditRecipient;
