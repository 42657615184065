import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import {
  BaseUrl,
  getByAddress,
  getKycByUserId,
} from "../constants/constantRoute";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const { address, isConnected } = useAccount();
  const [user, setUser] = useState(address);
  const [userId, setUserId] = useState(null);
  const [kycStatus, setKycStatus] = useState(null);
  const [userObject, setUserObject] = useState(null);

  useEffect(() => {
    if (isConnected) {
      axios
        .get(`${BaseUrl}${getByAddress}/${address}`)
        .then((res) => {
          setUserId(res.data.id);
          setUser(address);
          setUserObject(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (isConnected && userId) {
      axios
        .get(`${BaseUrl}/${getKycByUserId}${userId}`)
        .then((res) => {
          if (!res.data || !res.data.status) {
            setKycStatus(null);
            return;
          }
          setKycStatus(res.data.status);
        })
        .catch((err) => console.log(err));
    }
  }, [isConnected, userId]);

  return (
    <>
      <UserContext.Provider value={{ user, userId, userObject, kycStatus }}>
        {props.children}
      </UserContext.Provider>
    </>
  );
};

export default UserContextProvider;
