import React, { createContext, useState } from "react";

export const RecipientContext = createContext();

const RecipientProvider = ({ children }) => {
  const [recipientData, setRecipientData] = useState({});

  const updateRecipientData = (data) => {
    setRecipientData({ ...recipientData, ...data });
  };

  return (
    <RecipientContext.Provider value={{ recipientData, updateRecipientData }}>
      {children}
    </RecipientContext.Provider>
  );
};

export default RecipientProvider;
