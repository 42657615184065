import React, { useContext, useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { TransactionFormContext } from "../../contexts/TransactionFormContext";
import { RecipientContext } from "../../contexts/RecipientContext";
import { customStyles } from "./SelectStyles";
import { useNavigate } from "react-router-dom";
import ConfirmTransactionModal from "./ConfirmTransactionModal";
import axios from "axios";
import {
  BaseUrl,
  getBanksbyCountry,
  getRecipientsbyUserId,
  recipientDetails,
} from "../../constants/constantRoute";
import { UserContext } from "../../contexts/UserContext";

import { resolveBankAccountName } from "../../api/bank";
import { toast } from "react-toastify";

const Recipient = () => {
  const { transactionData } = useContext(TransactionFormContext);
  const { updateRecipientData } = useContext(RecipientContext);
  const { userId, user } = useContext(UserContext);
  const [bankOptions, setBankOptions] = useState([]);

  const [receiver, setReceiver] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [recipient, setRecipient] = useState([]);
  // const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (
      !transactionData.cryptoAmount ||
      !transactionData.cryptoCurrency ||
      !transactionData.fiatAmount ||
      !transactionData.fiatCurrency
    ) {
      navigate("/dashboard/transaction-form");
    }
  }, [transactionData, navigate]);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${getBanksbyCountry}`)
      .then((res) => {
        const sortedOptions = res.data
          .map((bank) => ({
            value: bank.id,
            label: bank.fullName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)); // Sort the options alphabetically by label

        setBankOptions(sortedOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${BaseUrl}/${getRecipientsbyUserId}${userId}`)
      .then((res) => setRecipient(res.data))
      .catch((err) => console.log(err));
  }, []);

  const getInitials = (fullName) => {
    const nameParts = fullName.split(/[ -]/); // Split by space or dash
    let initials = "";
    initials += nameParts[0].charAt(0); // Add initial of first name
    if (nameParts.length > 1) {
      initials += nameParts[nameParts.length - 1].charAt(0); // Add initial of last name
    }
    return initials.toUpperCase();
  };

  const handleRecipientClick = (selectedRecipient) => {
    setReceiver(selectedRecipient.id);
    setShowModal(true);
    setFormData({
      recipientEmail: selectedRecipient.email,
      recipientBank: {
        label: selectedRecipient.bank.fullName,
        value: selectedRecipient.bank.id,
      },
      recipientAccountNumber: selectedRecipient.accountNumber,
      recipientAccountName: selectedRecipient.fullName,
      // saveRecipientDetails: false,
    });
  };

  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formData, setFormData] = useState({
    recipientEmail: "",
    recipientBank: "",
    recipientAccountNumber: "",
    recipientAccountName: "",
    // saveRecipientDetails: false,
  });

  console.log(formData, 'hello')

  useEffect(() => {
    if (formData.recipientAccountNumber.length !== 10) return;
    _resoveBankAccountName();
  }, [formData.recipientAccountNumber, formData.recipientBank]);

  async function _resoveBankAccountName() {
    try {
      setLoading(true)
      const res = await resolveBankAccountName(
        formData.recipientAccountNumber,
        formData.recipientBank.value
      );
      setLoading(false)
      setFormData({ ...formData, recipientAccountName: res.data.account_name });
    } catch (error) {
      console.log(error);
      setLoading(false)
      setFormData({ ...formData, recipientAccountName: "" });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAccountNumberChange = (e) => {
    const { name, value } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: checked,
  //   }));
  // };

  const handleRecipientForm = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormError(true);
    } else {
      setFormError(false);

      try {
        setLoading(true);
        const res = await axios.post(`${BaseUrl}${recipientDetails}`, {
          userId: userId,
          accountNumber: formData.recipientAccountNumber,
          bankId: formData.recipientBank.value,
          fullName: formData.recipientAccountName,
          email: formData.recipientEmail,
        });

        if (res.data && res.data.id) {
          setReceiver(res.data.id);
          setShowModal(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Erorr", error);
        toast.error(error.response.data.message)
      }
    }
    setValidated(true);
  };

  return (
    <>
      <div className="recipient-form ">
        <div className="verify-account container  d-flex aligns-items-center flex-column mt-5">
          <Row className="justify-content-center align-items-center">
            <Col sm={3}>
              <button
                className="go-back btn"
                onClick={() => {
                  goBack();
                }}
              >
                <i class="bi bi-arrow-left"></i> back
              </button>
            </Col>
            <Col sm={9} className="">
              <div>
                <p className="send-details">
                  <span className="you-are-spending"> You are sending</span>
                  <span className="space">
                    <br />
                  </span>
                  <span className="transaction-data">
                    {transactionData.cryptoAmount}{" "}
                    {transactionData.cryptoCurrency}{" "}
                  </span>{" "}
                  to receive{" "}
                  <span className="transaction-data">
                    {" "}
                    {transactionData.fiatCurrency} {transactionData.fiatAmount}
                  </span>
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <p>Where are we sending the money to?</p>

              <div className="saved-recipients-container">
                <p>Saved Recipients</p>

                <div className="container">
                  <div className="row">
                    {recipient.map((d, i) => (
                      <div
                        key={i}
                        className="saved-recipient col-3 flex-column justify-content-center align-items-center"
                        onClick={() => handleRecipientClick(d)}
                      >
                        <div className="recipient-intitals mt-2 mb-2">
                          {getInitials(d.fullName)}
                        </div>
                        <p className="text-center">{d.fullName}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <Form
                className="mt-5"
                noValidate
                validated={validated}
                onSubmit={handleRecipientForm}
              >
                <Form.Group className="mb-5">
                  <Form.Label>Recipient email</Form.Label>
                  <Form.Control
                    type="email"
                    name="recipientEmail"
                    placeholder="johndoe@gmail.com"
                    value={formData.recipientEmail}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Select Bank</Form.Label>
                  <Select
                    className={
                      !formData.recipientBank && formError
                        ? "select-input-danger"
                        : "select-input"
                    }
                    options={bankOptions}
                    name="recipientBank"
                    placeholder="Select Bank"
                    blurInputOnSelect="true"
                    styles={customStyles}
                    value={formData.recipientBank}
                    onChange={(value) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        recipientBank: value,
                      }))
                    }
                  />
                  <p
                    className={
                      !formData.recipientBank && formError
                        ? "select-form-message"
                        : "select-form-message-none"
                    }
                  >
                    Please select rceipient's bank
                  </p>
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.recipientAccountNumber}
                    onChange={handleAccountNumberChange}
                    placeholder="012304878"
                    name="recipientAccountNumber"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    maxLength="10"
                    minLength="10"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid recipient account number.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John Doe"
                    name="recipientAccountName"
                    value={formData.recipientAccountName}
                    required
                    onChange={handleInputChange}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter recipient name.
                  </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group className="mb-3" id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Save recipient details for future transactions"
                    name="saveRecipientDetails"
                    value={formData.saveRecipientDetails}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group> */}

                <button disabled={loading} className="recipient-btn btn mx-3">{loading ? "Please Wait..." : "Continue"}</button>
              </Form>
            </Col>
          </Row>
          <ConfirmTransactionModal
            showModal={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            receiver={receiver}
            formData={formData}
            transactionData={transactionData}
            userAddress={user}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Recipient;
