import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard";
import { WagmiConfig, createClient, createConfig } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import {
  //mainnet,
  polygon,
  // optimism,
  // arbitrum,
  polygonMumbai,
  sepolia,
  bscTestnet,
  // Chain,
} from "wagmi/chains";
import TransactionForm from "./Components/Dashboard/TransactionForm";
import DashNavBar from "./Components/Dashboard/DashNavBar";
import VerifyAccount from "./Components/Dashboard/VerifyAccount";
import Recipient from "./Components/Dashboard/Recipient";
import UserContextProvider from "./contexts/UserContext";
import TransactionFormProvider from "./contexts/TransactionFormContext";
import RecipientProvider from "./contexts/RecipientContext";
import Recipients from "./Components/Dashboard/Recipients";
import { AddRecipient } from "./Components/Dashboard/AddRecipient";
import NotFound from "./Pages/NotFound";
import EditRecipient from "./Components/Dashboard/EditRecipient";
import History from "./Components/Dashboard/History";

const projectId = process.env.REACT_APP_PROJECT_ID;

const CustompolygonMumbai = {
  id: 80001,
  name: "Mumbai",
  nativeCurrency: { decimals: 18, name: "MATIC", symbol: "MATIC" },
  network: "Polygon Mumbai",
  rpcUrls: {
    public: { http: ["https://rpc-mumbai.maticvigil.com"] },
    default: { http: ["https://rpc-mumbai.maticvigil.com"] },
  },
};
const config = createConfig(
  getDefaultConfig({
    appName: "Coeral",
    projectId,
    // chains: [polygon, optimism, arbitrum, mainnet, polygonMumbai, sepolia],
    chains: [CustompolygonMumbai, sepolia, bscTestnet, polygon], // for testing purpose
  })
);

function App() {
  /* the below code i commented is bad for app performance; 
  you keep reassigning the projectId and client variables each time the app changes */
  // const projectId = process.env.REACT_APP_PROJECT_ID;

  // const client = createClient(
  //   getDefaultClient({
  //     appName: "Coeral",
  //     projectId,
  //     // chains: [polygon, optimism, arbitrum, mainnet, polygonMumbai, sepolia],
  //     chains:[polygonMumbai]
  //   })
  // );

  return (
    <div className="App">
      <WagmiConfig config={config}>
        <ConnectKitProvider
          theme="soft"
          customTheme={{
            "--ck-overlay-background": "rgba(0,0,0,0.5)",
            "--ck-border-radius": "none",
            "--ck-primary-button-active-color": "#fff",
          }}
          options={{
            walletConnectName: <>Wallet Connect</>,
          }}
        >
          <UserContextProvider>
            <TransactionFormProvider>
              <RecipientProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path="/dashboard" element={<Dashboard />}>
                      <Route
                        path="/dashboard/transaction-form"
                        element={<TransactionForm />}
                      />
                      <Route
                        path="/dashboard/verify-account"
                        element={<VerifyAccount />}
                      />
                      <Route
                        path="/dashboard/recipient"
                        element={<Recipient />}
                      />
                      <Route
                        path="/dashboard/recipients"
                        element={<Recipients />}
                      />
                      <Route
                        path="/dashboard/add-recipient"
                        element={<AddRecipient />}
                      />
                      <Route
                        path="/dashboard/edit-recipient/:id"
                        element={<EditRecipient />}
                      />
                      <Route
                        path="/dashboard/transaction-history"
                        element={<History />}
                      />
                      {/* <Route path="/dashboard*" element={<NotFound />} /> */}
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </BrowserRouter>
              </RecipientProvider>
            </TransactionFormProvider>
          </UserContextProvider>
        </ConnectKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
