import React from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LightLogo from "../images/Logo-Light.png";
import NotFound_img from "../images/404.png";

const NotFound = () => {
  return (
    <>
      <div className="notFound-container">
        <Navbar className="mt-3">
          <Container>
            <Navbar.Brand href="/dashboard">
              <img src={LightLogo} alt="logo" className="logo" />
            </Navbar.Brand>
          </Container>
        </Navbar>

        <Container className="mt-5 notFound-body pb-5">
          <Row className="oops">
            <Col lg>
              {" "}
              <h1>Ooops!</h1>
              <div className="notFound-img-mobile mb-4"></div>
              <p>
                The page you are looking for is not available or might have been
                removed
              </p>
              <span>
                You can click the button below to go back to the home page
              </span>
              <Link to="/" className="notFound-btn btn mt-5">
                Home
              </Link>
            </Col>
            <Col lg className="notFound-img"></Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NotFound;
