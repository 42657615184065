import axios from "axios";
import { BaseUrl, getTransaction } from "../constants/constantRoute";

export async function getUserTransactions(user_address) {
  try {
    if (!user_address) return [];
    const res = await axios.get(`${BaseUrl}${getTransaction}/${user_address}`);
    if (!res.data) return [];
    return [...res.data];
  } catch (error) {
    throw error;
  }
}
