import React, { useState, useEffect, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import VerifyModal from "./VerifyModal";
import { customStyles } from "./SelectStyles";
import axios from "axios";
import {
  BaseUrl,
  countryRoute,
  kycType,
  kycVerifyRoute,
  getByAddress,
  REGIONS,
} from "../../constants/constantRoute";
import { getCountryCodes } from "../../api/countryCode";
import { UserContext } from "../../contexts/UserContext";

const VerifyAccount = () => {
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [docOptions, setDocOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [callingCodeId, setcallingCodeId] = useState("");
  const { userId } = useContext(UserContext);
  const [currentRegion, setCurrentRegion] = useState(null);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    country: "",
    email: "",
    type: "",
    photo: null,
    document: null,
  });

  // useEffect(() => {
  //   axios
  //     .get(`${BaseUrl}${countryRoute}`)
  //     .then((res) => {
  //       setCountryOptions(
  //         res.data.map((country) => ({
  //           value: country.id,
  //           label: country.country,
  //           callingCodeId: `${country.code}`,
  //         }))
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${kycType}`)
      .then((res) => {
        setDocOptions(
          res.data.map((docType) => ({
            value: docType.id,
            label: docType.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    _getCountryCodesByRegion();
  }, [currentRegion]);

  async function _getCountryCodesByRegion() {
    if (!currentRegion) return;
    const countries = await getCountryCodes(currentRegion.value);
    setCountryOptions(
      countries.map((country) => ({
        value: country.id,
        label: country.country,
        callingCodeId: `${country.id}`,
      }))
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setcallingCodeId(selectedOption ? selectedOption.callingCodeId : "");
    setFormData((prevState) => ({
      ...prevState,
      country: selectedOption.value,
      callingCodeId: selectedOption.value,
    }));
  };

  // const handlePhoneNumberChange = (event) => {
  //   const newPhoneNumber = event.target.value.replace(/(\+\d+)/g, "");
  //   if (/^\d*$/.test(newPhoneNumber)) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       phoneNumber: `${newPhoneNumber}`,
  //     }));
  //   }
  // };

  const handleDocumentChange = (selectedOption) => {
    setSelectedDocument(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      type: selectedOption.value,
    }));
  };

  const handleVerifyForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormError(true);
    } else {
      setFormError(false);
      const _formData = new FormData();
      _formData.append("userId", userId);
      _formData.append("firstname", formData.firstname);
      _formData.append("lastname", formData.lastname);
      _formData.append("email", formData.email);
      // _formData.append("phoneNumber", formData.phoneNumber);
      _formData.append("type", formData.type);
      _formData.append("callingCodeId", formData.callingCodeId);
      _formData.append("document", formData.document);
      _formData.append("photo", formData.photo);
      formData.userId = userId;
      formData.country = undefined;
      setFormIsLoading(true);
      axios
        .post(`${BaseUrl}${kycVerifyRoute}`, _formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          setModalShow(true);
          setFormIsLoading(false);
        })
        .catch((err) => {
          setFormIsLoading(false);
          const message = err.response.data.message;
          alert(message);
          console.log(err);
        });
    }
    setValidated(true);
  };

  return (
    <>
      <div className="verify-account container d-flex aligns-items-center flex-column mt-5">
        <h6>Verify account</h6>
        <p>complete verification to process transactions</p>

        <Form
          className="mt-4"
          noValidate
          validated={validated}
          onSubmit={handleVerifyForm}
        >
          <Row className="mb-sm-5">
            <Col sm className="form-column">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="John"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid first name.
              </Form.Control.Feedback>
            </Col>
            <Col sm className="form-column">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Doe"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid last name.
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-sm-5">
            <Col sm controlId="formGridState" className="form-column">
              <Form.Label>Country Region</Form.Label>
              <Select
                className={
                  !currentRegion && formError
                    ? "select-input-danger"
                    : "select-input"
                }
                options={REGIONS.map((value) => ({ value, label: value }))}
                required
                name="regions"
                value={currentRegion}
                placeholder="Select Region"
                blurInputOnSelect="true"
                onChange={(_region) => setCurrentRegion(_region)}
                styles={customStyles}
              />
              <p
                className={
                  !currentRegion && formError
                    ? "select-form-message"
                    : "select-form-message-none"
                }
              >
                Please select Region
              </p>
            </Col>
            <Col sm controlId="formGridState" className="form-column">
              <Form.Label>Country</Form.Label>
              <Select
                className={
                  !formData.country && formError
                    ? "select-input-danger"
                    : "select-input"
                }
                options={countryOptions}
                required
                name="country"
                value={selectedCountry}
                placeholder="Select Country"
                blurInputOnSelect="true"
                onChange={handleCountryChange}
                styles={customStyles}
              />
              <p
                className={
                  !formData.country && formError
                    ? "select-form-message"
                    : "select-form-message-none"
                }
              >
                Please select valid country
              </p>
            </Col>
          </Row>
          <Row className="mb-sm-5">
            <Col sm className="form-column">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="johndoe@gmail.com"
                name="email"
                onChange={handleInputChange}
                value={formData.email}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email.
              </Form.Control.Feedback>
            </Col>
            <Col sm className="form-column">
              <Form.Label>Take a photo</Form.Label>
              <Form.Control
                required
                type="file"
                accept=".png, .jpg, .jpeg"
                placeholder="Jothan"
                name="photo"
                onChange={handleFileChange}
              />
              <Form.Control.Feedback type="invalid">
                Please upload the correct file format.
              </Form.Control.Feedback>
            </Col>
            {/* <Col sm className="form-column">
              <Form.Label>Phone Number</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="callingCodeId"
                  required
                  type="text"
                  value={callingCodeId}
                  readOnly
                />
                <Form.Control
                  className="phoneNumber"
                  required
                  type="text"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  maxLength="10"
                  minLength="10"
                  name="phoneNumber"
                  onChange={handlePhoneNumberChange}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                Please enter a valid phone number.
              </Form.Control.Feedback>
            </Col> */}
          </Row>
          <Row className="mb-sm-5">
            <Col sm controlId="formGridState" className="form-column">
              <Form.Label>Verification document</Form.Label>
              <Select
                className={
                  !formData.type && formError
                    ? "select-input-danger"
                    : "select-input"
                }
                options={docOptions}
                required
                name="type"
                value={selectedDocument}
                placeholder="Select Document Type"
                blurInputOnSelect="true"
                onChange={handleDocumentChange}
                styles={customStyles}
              />
              <p
                className={
                  !formData.type && formError
                    ? "select-form-message"
                    : "select-form-message-none"
                }
              >
                Please select valid document type
              </p>
            </Col>
            <Col sm className="form-column">
              <Form.Label>Upload document</Form.Label>
              <Form.Control
                required
                type="file"
                accept=".png, .jpg, .jpeg, .pdf"
                placeholder="johndoe.pdf"
                name="document"
                onChange={handleFileChange}
              />
              <Form.Control.Feedback type="invalid">
                Please upload the correct file format.
              </Form.Control.Feedback>
            </Col>
          </Row>
          <button disabled={formIsLoading} className="verify-btn btn mx-auto">
            {formIsLoading ? "Loading..." : "Verify Account"}
          </button>
          <VerifyModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          />
        </Form>
      </div>
    </>
  );
};

export default VerifyAccount;
