import axios from "axios";
import { BaseUrl, resolveAccountName } from "../constants/constantRoute";

export async function resolveBankAccountName(account_no, bankId) {
  try {
    const res = await axios.get(`${BaseUrl}${resolveAccountName}?bank-id=${bankId}&account-no=${account_no}`);
    if (!res.data) throw new Error('Something went wrong')
    return res.data
  } catch (error) {
    throw error
  }
}