export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    color: state.selectProps.value ? "#fff" : "#000",
    backgroundColor: state.isFocused ? "#081520" : "#081520",
    borderColor: state.isFocused ? "#333" : "#ccc",
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "#078c6ce8",
    backgroundColor: "#081520",
  }),

  // option: (provided, state) => ({
  //   ...provided,
  //   backgroundColor: state.isSelected ? "#078c6ce8" : "",
  // }),

  placeholder: (provided) => ({
    ...provided,
    color: "white",
  }),
};
