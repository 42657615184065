import { Chain } from "wagmi";
import { COERAL_CONTRACT_ADDRESS, supportedTokens } from "../web3/constants";
export function dateFormater(date) {
  const _dateObj = new Date(date);
  const year = _dateObj.getFullYear();
  const month = _dateObj.getMonth() + 1;
  const _date = _dateObj.getDate();

  const hour = _dateObj.getHours();
  const minutes = _dateObj.getMinutes();

  const formatedDate = `${getMonth(month)} ${_date}, ${year}`;
  const formattedTime = formatTime(hour);
  const _minutes = minutes < 10 ? `0${minutes}` : minutes;
  return {
    date: formatedDate,
    time: `${formattedTime.formatted}:${_minutes} ${formattedTime.sup}`,
  };
}

export function truncate(hash) {
  if (hash.length > 20) {
    const _hash = `${hash}`.slice(0, 10);
    return `${_hash}...${hash.slice(55)}`;
  }
  return hash;
}

function formatTime(hours) {
  let _hours = hours > 12 ? hours - 12 : hours;
  return {
    formatted: _hours,
    sup: hours > 12 ? "pm" : "am",
  };
}

function getMonth(_month) {
  switch (_month) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      return "Jan";
  }
}

export function addCommasToNumber(_number) {
  // Convert number to string
  const numberStr = _number.toString();

  // Split the string into integer and decimal parts (if any)
  const parts = numberStr.split(".");

  // Get the integer part
  const integerPart = parts[0];

  // Add commas to the integer part
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Check if there is a decimal part
  const decimalPart = parts.length > 1 ? "." + parts[1] : "";

  // Combine the formatted integer and decimal parts
  const formattedNumber = `${formattedInteger}${decimalPart}`;

  return formattedNumber;
}

export function resolveNetwork(chain) {
  switch (chain.id) {
    case 80001:
      return "mumbai";
    case 97:
      return "bsc_testnet";
    case 11155111:
      return "sepolia";
    default:
      return "polygon";
  }
}

export function resolveTokenContractAddress(chain, currency) {
  if (!chain) return "";
  switch (chain.id) {
    case 80001:
      return supportedTokens.testnet[currency];
    case 97:
      return supportedTokens.bsc_testnet[currency];
    case 11155111:
      return supportedTokens.sepolia[currency];
    default:
      return supportedTokens.mainnet[currency];
  }
}

export function resolveCoeralContractAddress(chain) {
  if (!chain) return "";
  switch (chain.id) {
    case 80001:
      return COERAL_CONTRACT_ADDRESS.testnet;
    case 97:
      return COERAL_CONTRACT_ADDRESS.bsc_testnet;
    case 11155111:
      return COERAL_CONTRACT_ADDRESS.sepolia;
    default:
      return COERAL_CONTRACT_ADDRESS.mainnet;
  }
}
