import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useContractWrite, useContractRead, useNetwork } from "wagmi";
import { Modal, Button } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";

import * as COERALABI from "../../web3/ABIs/COERAL_ABI";
import * as ERC20_ABI from "../../web3/ABIs/ERC20_ABI";

import { COERAL_CONTRACT_ADDRESS, supportedTokens } from "../../web3/constants";

import { supportedFiat, TRANSACTION_FEE } from "../../constants/constantRoute";
import {
  resolveCoeralContractAddress,
  resolveTokenContractAddress,
} from "../../constants/helpers";

const ConfirmTransactionModal = ({
  showModal,
  onHide,
  formData,
  transactionData,
  receiver,
  userAddress,
}) => {
  const { kycStatus } = useContext(UserContext);
  const navigate = useNavigate();
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [showKycModal, setKycShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [approve, setApprove] = useState(true);

  const { chain } = useNetwork();

  const {
    data: cryptoAllowance,
    refetch,
    isRefetching,
  } = useContractRead({
    abi: ERC20_ABI.ABI,
    address: resolveTokenContractAddress(
      chain,
      transactionData.cryptoCurrency.toLowerCase()
    ),
    functionName: "allowance",
    args: [userAddress, resolveCoeralContractAddress(chain)],
  });

  const { data: crytoDecimal } = useContractRead({
    abi: ERC20_ABI.ABI,
    address: resolveTokenContractAddress(
      chain,
      transactionData.cryptoCurrency.toLowerCase()
    ),
    functionName: "decimals",
  });

  const {
    data: trxResult,
    error,
    isLoading,
    isSuccess,
    write: transferCryptoToContract,
  } = useContractWrite({
    abi: COERALABI.ABI,
    address: resolveCoeralContractAddress(chain),
    functionName: "deposit",
    args: [
      transactionData.cryptoAmount
        ? ethers.utils.parseUnits(
            transactionData.cryptoAmount.toString(),
            crytoDecimal
          )
        : ethers.utils.parseEther("0"),
      resolveTokenContractAddress(
        chain,
        transactionData.cryptoCurrency.toLowerCase()
      ),
      supportedFiat[transactionData.fiatCurrency],
      receiver.toString(),
      crytoDecimal,
    ],
  });
  const {
    data: approveResult,
    error: approveErr,
    isLoading: load,
    isSuccess: approveSuccess,
    write: approveCrypto,
    status,
  } = useContractWrite({
    abi: ERC20_ABI.ABI,
    address: resolveTokenContractAddress(
      chain,
      transactionData.cryptoCurrency.toLowerCase()
    ),
    functionName: "approve",
    args: [
      resolveCoeralContractAddress(chain),
      transactionData.cryptoAmount
        ? ethers.utils.parseUnits(
            transactionData.cryptoAmount.toString(),
            crytoDecimal
          )
        : ethers.utils.parseEther("0"),
    ],
  });

  const openModal = (message) => {
    setModalMessage(message);
    setKycShowModal(true);
  };
  const closeModal = () => {
    setKycShowModal(false);
  };

  const goToVerifyPage = () => {
    navigate("/dashboard/verify-account");
    closeModal();
  };

  const handleCheckKyc = (_onHide) => {
    // if (!kycStatus) {
    //   openModal(
    //     "You are required to verify your account to complete transaction"
    //   );
    //   goToVerifyPage();
    // } else if (kycStatus === 1) {
    //   // openModal("KYC Verification still pending!!");
    //   openModal("KYC Verfication is still being reviewed!");
    //   _onHide();
    // } else if (kycStatus === 3) {
    //   openModal("KYC verification failed. Please verify your account again.");
    //   goToVerifyPage();
    // } else {
    if (approve) {
      setApprovalLoading(true);
      approveCrypto();
      return;
    }
    transferCryptoToContract();
    // }
  };

  function checkAllowance() {
    if (cryptoAllowance && crytoDecimal) {
      const _allowance = +ethers.utils.formatUnits(
        cryptoAllowance,
        crytoDecimal
      );
      const _amount = transactionData.cryptoAmount.toString();
      if (_allowance < +_amount) {
        setApprove(true);
        return;
      }
      setApprove(false);
    }
  }

  useEffect(() => {
    checkAllowance();
  }, [cryptoAllowance, crytoDecimal]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      if (!kycStatus || kycStatus === 3 || kycStatus === 1) {
        setKycShowModal(true);
        setModalMessage(
          !kycStatus
            ? "You have not Submitted your Kyc"
            : kycStatus === 3
            ? "Your Kyc was rejected. Please Resubmit your Kyc"
            : kycStatus === 1
            ? "Your Kyc is being reviewed"
            : ""
        );
        return;
      }

      setShowConfirmModal(true);

      // setTimeout(() => {
      //   setShowConfirmModal(false);
      //   navigate("/dashboard/transaction-form"); // Replace with the desired URL
      // }, 3000);
    }
    if (error) {
      setShowConfirmModal(true);
      // setTimeout(() => {
      //   setShowConfirmModal(false);
      // }, 3000);
    }
  }, [isSuccess, error, navigate]);

  useEffect(() => {
    if (approveSuccess && approveResult) {
      // approveResult.wait(1).then((receipt) => {
      //   setApprovalLoading(false);
      //   refetch();
      // });
      setTimeout(() => {
        if (status === "success") {
          setApprovalLoading(false);
          refetch();
        }
      }, 15000);
    }
    if (approveErr) {
      setApprovalLoading(false);
      alert(approveErr.message);
    }
  }, [approveSuccess, approveErr, status]);

  return (
    <>
      <div>
        <Modal
          show={showKycModal}
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="KycStatusmodal"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="mx-auto mt-5"
            >
              Hey!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{modalMessage}</p>
            <p>Contact us if your KYC status is still being review for long</p>
            <p>
              Complete your Kyc. Otherwise, your funds won't be transfered to
              your Local Account
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!kycStatus || kycStatus === 3 ? (
              <Button onClick={goToVerifyPage} className="kycStatus-btn mt-5">
                Go to Verify Page
              </Button>
            ) : (
              <Button
                onClick={() => setKycShowModal(false)}
                className="kycStatus-btn mt-5"
              >
                Okay
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModal}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          className="confirm-transaction-modal"
        >
          <Modal.Header>
            <Modal.Title>
              <p> Confirm Transaction</p>

              <div className="icon-convert mx-auto">
                <span className=" d-flex justify-content-center align-items-center mt-5">
                  <div className="icon-container me-1">
                    <span
                      className={`${
                        transactionData.cryptoCurrency === "USDT"
                          ? "usdt-icon"
                          : "usdc-icon"
                      }`}
                    ></span>
                  </div>
                  <i class="bi bi-caret-left-fill"></i>
                  <div className="border w-100"></div>
                  <i class="bi bi-caret-right-fill"></i>
                  <div className="icon-container ms-1">
                    <span className="fiat-icon"></span>
                  </div>
                </span>
              </div>

              <p className="convert mt-5">
                You are converting {transactionData.cryptoAmount}{" "}
                {transactionData.cryptoCurrency} for{" "}
                {transactionData.fiatCurrency} {transactionData.fiatAmount}
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <p>Receipient to receive</p>
              <p className="text-white">
                {transactionData.fiatCurrency} {transactionData.fiatAmount}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Conversion rate</p>
              <p className="text-white">
                1 {transactionData.cryptoCurrency} ={" "}
                {transactionData.fiatCurrency} {transactionData.conversionRate}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Transaction Fee</p>
              <p className="text-white">
                {TRANSACTION_FEE} {transactionData.cryptoCurrency}
              </p>
            </div>
            <p className="text-white mt-2">Recipient Details</p>
            <div className="d-flex justify-content-between">
              <p>Account Name:</p>
              <p className="text-white">{formData.recipientAccountName}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Email:</p>
              <p className="text-white">{formData.recipientEmail}</p>
            </div>
            <p className="text-white mt-2">Conversion Details</p>
            <div className="d-flex justify-content-between">
              <p>Conversion Method:</p>
              <p className="text-white">Bank Transfer</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Bank Name:</p>
              <p className="text-white">{formData.recipientBank?.label}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Account Number:</p>
              <p className="text-white">{formData.recipientAccountNumber}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleCheckKyc(onHide);
              }}
              className="confirm-btn"
              disabled={isLoading || load || approvalLoading || isRefetching}
            >
              {isLoading || load || approvalLoading
                ? "Loading..."
                : approve
                ? "Approve Crypto"
                : "Confirm Transaction"}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                onHide();
              }}
              className="confirm-btn"
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          centered
          className="confirmTransactionModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="mt-3">
            {isSuccess ? (
              <div>
                <h2 className="text-center ">Transaction is being Processed!</h2>
                <p className="confirm-text text-center mt-4">
                  Wait while we send your funds to the specified account.
                </p>
                <div className="py-3 d-flex justify-content-center align-items-center">
                  <button
                    className="confirm-btn btn btn-primary"
                    onClick={() => {
                      setShowConfirmModal(false);
                      navigate("/dashboard/transaction-form");
                    }}
                  >
                    Go to Dashboard
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h2 className="text-center text-danger">Error</h2>
                <p className="confirm-text text-center mt-4">
                  {error && error.message}
                </p>
                <button
                  className="confirm-btn btn btn-primary"
                  onClick={() => {
                    setShowConfirmModal(false);
                    navigate("/dashboard/transaction-form");
                  }}
                >
                  Okay
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ConfirmTransactionModal;
