import axios from "axios";
import {
  BaseUrl,
  getRecipientsbyUserId,
  deleteRecipientbyID,
} from "../constants/constantRoute";

export async function getUserRecipients(userId) {
  try {
    if (!userId) return [];
    const res = await axios.get(`${BaseUrl}/${getRecipientsbyUserId}${userId}`);
    if (!res.data) return [];
    return [...res.data];
  } catch (error) {
    throw error;
  }
}

export async function deleteRecipient(id) {
  try {
    if (!id) return false;
    const res = await axios.delete(`${BaseUrl}/${deleteRecipientbyID}${id}`);
    if (!res.data) throw new Error("Couldn't Delete");
    return true;
  } catch (error) {
    throw error;
  }
}
