import { Navigation } from "../Components/Landing Page/Navigation";
import "../css/landing-page.css";
import coin from "../images/Coin.svg";
import wallet from "../images/Wallet.svg";
import shield from "../images/Shield.svg";
import frameDark from "../images/Connect wallet.svg";
import frameLight from "../images/Connect wallet 2.svg";
import DarkLogo from "../images/Logo-Light.png";
import LightLogo from "../images/Logo-Dark.png";
import { Faq } from "../Components/Landing Page/Faq";
import { useContext, useEffect, useState } from "react";
import ConvertComponent from "../Components/Landing Page/ConvertComponent";
// import TransactionForm from "../Components/Dashboard/TransactionForm";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ConnectKitButton } from "connectkit";
import { useAccount } from "wagmi";
import { HashLoader } from "react-spinners";
import axios from "axios";
import { userRoute, BaseUrl } from "../constants/constantRoute";
import { UserContext } from "../contexts/UserContext";
const Home = () => {
  const { user, kycStatus } = useContext(UserContext);
  const { isConnected } = useAccount();
  let navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const [toRecipientPage, settoRecipientPage] = useState(false);

  useEffect(() => {
    async function postWalletAddress() {
      if (isConnected) {
        try {
          const response = await axios.post(`${BaseUrl}${userRoute}`, {
            walletAddress: user,
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    checkConnected();
    postWalletAddress();
  }, [isConnected, user]);

  const checkConnected = () => {
    if (isConnected) {
      // console.log("Connected");
      // console.log(kycStatus);
      // console.log("User is ", user);
      if (toRecipientPage) {
        setTimeout(() => {
          navigate("/dashboard/recipient");
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/dashboard/transaction-form");
        }, 2000);
      }
    } else {
      // console.log("Disconnected");
    }
  };

  return (
    <>
      {loading ? (
        <HashLoader
          color={"#009d77"}
          loading={loading}
          cssOverride
          size={150}
          className="loader"
        />
      ) : (
        <div data-theme="dark" className="page">
          {/* <div data-theme={darkMode ? "dark" : "light"} className="page"></div> */}
          <div
            className="dark-light ms-4"
            onClick={() => setDarkMode(!darkMode)}
          >
            {/* <i className={darkMode ? "bi bi-sun-fill" : "bi bi-moon-fill"}></i> */}
          </div>
          <Navigation darkMode={darkMode} setDarkMode={setDarkMode} />

          <div className="container mt-sm-5 mb-5  pb-5">
            <div className="row">
              <div className="col-lg-6 ">
                <h2 className="convert text-justify mt-sm-3">
                  Convert Crypto to Fiat in Minutes
                </h2>
                <p className="convert-text mt-4">
                  Lorem ipsum dolor sit amet consectetur. Augue purus duis
                  gravida augue scelerisque suspendisse.{" "}
                </p>

                <div className="greenbox-container row mt-5 text-center ">
                  <div className="green-box col-lg-12">
                    {" "}
                    Secured transaction
                  </div>
                  <div className="green-box col-lg-12 mx-3 ">
                    Directly to bank account
                  </div>
                  <div className="green-box col-lg-12 mt-4">
                    {" "}
                    Speedy delivery
                  </div>
                  <div className="green-box col-lg-12 mt-4 mx-3">
                    Convert any crypto currency
                  </div>
                </div>

                <ConnectKitButton.Custom>
                  {({ isConnected, show, truncatedAddress, ensName }) => {
                    return (
                      <div className="connect-wallet-d-none gap-2 col-12 mx-auto mt-3">
                        <button
                          className="connect-wallet-btn-small btn"
                          onClick={show}
                        >
                          {isConnected
                            ? ensName ?? truncatedAddress
                            : "Connect Wallet"}
                        </button>
                      </div>
                    );
                  }}
                </ConnectKitButton.Custom>
              </div>
              <div className="crypto-form col-lg-6">
                <ConvertComponent
                  settoRecipientPage={settoRecipientPage}
                  toRecipientPage={toRecipientPage}
                />
                {/* <TransactionForm /> */}
              </div>
            </div>
          </div>

          <section className="convert-in-mins d-flex flex-column justify-content-center align-items-center">
            <h2 className="text-center">Convert Crypto to Fiat in Minutes</h2>

            <div className="contain container">
              <div className="row justify-content-center">
                <div className="col-6 box px-4">
                  <h6 className="mt-5">Cheap and affordable</h6>
                  <p className="mt-4">
                    Bonafide exchange rate are the most cost effective
                  </p>
                  <img src={coin} alt="" className="coin" />
                </div>
                <div className="col-6 box px-4">
                  <h6 className="mt-5">Instant off-ramp</h6>
                  <p className="mt-4">
                    Cash-out fiat directly to your bank account
                  </p>
                  <img src={wallet} alt="" className="wallet" />
                </div>
                <div className="col-12 box px-4 mt-5">
                  <div className="d-sm-flex ">
                    <div>
                      {" "}
                      <h6 className="mt-5 ps-4">Private and secure</h6>
                      <p className="mt-4 ps-4 security">
                        Bonafide prioritizes the security of your funds, we
                        securely process your transaction.{" "}
                      </p>
                    </div>
                    <div>
                      <img src={shield} alt="" className="shield rounded" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="easily-convert d-flex justify-content-center align-items-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-6">
                  <h5 className="my-5 ">Easily convert to fiat in few steps</h5>
                  <div className="connect-div d-flex mt-5 pt-3">
                    <div className="line "></div>
                    <div className="">
                      <p>Connect wallet and enter amount</p>
                      <p className="line-small">
                        Lorem ipsum dolor sit amet consectetur. Augue purus duis
                        gravida augue scelse.{" "}
                      </p>
                    </div>
                  </div>
                  <p className=" mt-5 ">Enter recipient’s detail</p>
                  <p className="mt-5 ">Confirm and sign transaction</p>
                </div>
                <div className="crypto-form col-sm-6 d-flex justify-content-center align-items-center">
                  <img src={darkMode ? frameDark : frameLight} alt="" />
                </div>
              </div>
            </div>
          </section>

          <Faq />

          <footer className="footer-section">
            <div className="container">
              <div className="row">
                <div className="footer-logo col-sm-4 ">
                  {/* <p className="logo">coeral</p> */}
                  <img
                    src={darkMode ? DarkLogo : LightLogo}
                    alt="logo"
                    className="logo"
                  />

                  <p className="footer-logo-text mt-3">
                    Convert Crypto to Fiat in Minutes
                  </p>
                </div>
                <div className="col-sm-4 text-center footer-links">
                  <p className="">Help and Support</p>
                  <ul className="list-unstyled footer-list">
                    <div className="d-lg-flex justify-content-center">
                      <li className="mt-2">
                        <a href="/">Our blog</a>
                      </li>
                      <li className="ms-3 mt-sm-2">
                        <a href="/">Privacy Policy</a>
                      </li>
                    </div>
                    <li className="mt-2">
                      <a href="/">Terms of service</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-4 text-center">
                  <p className="footer-socials">Socials</p>
                  <ul className="list-unstyled">
                    <div className="social-list d-flex justify-content-between">
                      <li>
                        <a href="/">
                          <i className="bi bi-twitter"></i>
                        </a>
                      </li>
                      <li className="">
                        <a href="/">
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </li>
                      <li className="">
                        <a href="/">
                          <i className="bi bi-instagram"></i>
                        </a>
                      </li>
                      <li className="">
                        <a href="/">
                          <i className="bi bi-medium"></i>
                        </a>
                      </li>
                      <li className="">
                        <a href="/">
                          <i className="bi bi-youtube"></i>
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default Home;
