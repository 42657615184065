import React, { useState, useEffect, useContext } from "react";
import { Alert, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { customStyles } from "../Dashboard/SelectStyles";
import { useAccount, useBalance, useContractRead, useNetwork } from "wagmi";
import { TransactionFormContext } from "../../contexts/TransactionFormContext";
import { ConnectKitButton } from "connectkit";
import { ethers } from "ethers";
import axios from "axios";
import {
  BaseUrl,
  getCryptoCurrency,
  getFiatCurrency,
  TRANSACTION_FEE,
} from "../../constants/constantRoute";

import * as ERC20_ABI from "../../web3/ABIs/ERC20_ABI";
import { supportedTokens } from "../../web3/constants";
import { resolveNetwork } from "../../constants/helpers";

const ConvertComponent = ({ toRecipientPage, settoRecipientPage }) => {
  let navigate = useNavigate();
  const { address, isConnected } = useAccount();
  const { data } = useBalance({
    address: address,
  });
  const { transactionData, updateTransactionData } = useContext(
    TransactionFormContext
  );

  const { chain } = useNetwork();

  const [cryptoAmount, setCryptoAmount] = useState("");
  const [cryptoCurrency, setCryptoCurrency] = useState("");
  const [fiatAmount, setFiatAmount] = useState("");
  const [fiatCurrency, setFiatCurrency] = useState("");
  const [conversionRate, setConversionRate] = useState(null);
  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState(false);
  const [fiatOptions, setFiatOptions] = useState([]);
  const [cryptoOptions, setCryptoOptions] = useState([]);

  const [currentCryptoAddress, setCurrentCryptoAddress] = useState("");

  const { data: cryptoBalance } = useContractRead({
    abi: ERC20_ABI.ABI,
    address: currentCryptoAddress,
    functionName: "balanceOf",
    args: [address ? address : ""],
  });
  const { data: crytoDecimal } = useContractRead({
    abi: ERC20_ABI.ABI,
    address: currentCryptoAddress,
    functionName: "decimals",
  });

  function getNetwork() {
    if (!chain) return false;
    return chain.network === "matic";
  }

  useEffect(() => {
    const fetchCryptoCurrencyOptions = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/${getCryptoCurrency}`);
        setCryptoOptions(
          response.data.map((crypto) => ({
            value: crypto.id,
            label: crypto.currency,
          }))
        );
        setCryptoCurrency(response.data[0].currency);
      } catch (error) {
        console.error("Error fetching crypto currency options:", error);
      }
    };

    const fetchFiatCurrencyOptions = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/${getFiatCurrency}`);
        setFiatOptions(
          response.data.map((fiat) => ({
            value: fiat.id,
            label: fiat.currency,
          }))
        );
        setFiatCurrency(response.data[0].currency);
      } catch (error) {
        console.error("Error fetching fiat currency options:", error);
      }
    };

    fetchCryptoCurrencyOptions();
    fetchFiatCurrencyOptions();
  }, []);

  useEffect(() => {
    const fetchConversionRate = async () => {
      if (cryptoAmount && cryptoCurrency && fiatCurrency) {
        try {
          const response = await axios.get(
            `${BaseUrl}/rate/${cryptoCurrency}/${fiatCurrency}`
          );

          // console.log("Data: ", response.data);
          const rate = response.data["amount"];
          // console.log("Rate: ", rate);
          setConversionRate(rate);
          const convertedAmount = rate * (+cryptoAmount - TRANSACTION_FEE);
          setFiatAmount(convertedAmount.toFixed(2));
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchConversionRate();
  }, [cryptoAmount, cryptoCurrency, fiatCurrency]);

  useEffect(() => {
    const {
      cryptoAmount,
      cryptoCurrency,
      fiatAmount,
      fiatCurrency,
      conversionRate,
    } = transactionData;

    setCryptoAmount(cryptoAmount || "");
    setCryptoCurrency(cryptoCurrency || "");
    setFiatAmount(fiatAmount || "");
    setFiatCurrency(fiatCurrency || "");
    setConversionRate(conversionRate || "");
  }, [transactionData]);

  useEffect(() => {
    function getSelectedCryptoAddress() {
      if (cryptoOptions.length <= 0) return;
      const selectedCrypto = cryptoOptions.find(
        (c) => c.label === cryptoCurrency
      );
      if (!selectedCrypto) return;
      let tokenAddress = "";
      if (!selectedCrypto || !chain) return;
      const network = resolveNetwork(chain);
      if (network === "polygon") {
        tokenAddress =
          supportedTokens.mainnet[selectedCrypto.label.toLowerCase()];
      } else if (network === "bsc_testnet") {
        tokenAddress =
          supportedTokens.bsc_testnet[selectedCrypto.label.toLowerCase()];
      } else if (network === "sepolia") {
        tokenAddress =
          supportedTokens.sepolia[selectedCrypto.label.toLowerCase()];
      } else {
        tokenAddress =
          supportedTokens.testnet[selectedCrypto.label.toLowerCase()];
      }

      if (!tokenAddress) return;
      setCurrentCryptoAddress(tokenAddress);
    }
    getSelectedCryptoAddress();
  }, [cryptoCurrency, chain, cryptoOptions]);

  const handleCryptoAmountChange = (e) => {
    const input = e.target.value;
    if (/^\d*\.?\d*$/.test(input)) {
      setCryptoAmount(input);
    }
  };

  const handleCryptoCurrencyChange = (cryptoCurrency) => {
    setCryptoCurrency(cryptoCurrency.label);
  };

  const handleFiatCurrencyChange = (fiatCurrency) => {
    setFiatCurrency(fiatCurrency.label);
  };

  const handleSubmit = (e, show) => {
    e.preventDefault();
    const form = e.currentTarget;
    // console.log(data?.formatted);

    let userBal = 0;
    if (cryptoBalance && crytoDecimal) {
      userBal = +ethers.utils.formatUnits(cryptoBalance, crytoDecimal);
    }

    if (form.checkValidity() === false || !cryptoCurrency || !fiatCurrency) {
      e.stopPropagation();
      setFormError(true);
      toast.error("Complete all fields!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (cryptoAmount > userBal) {
      toast.error("Insufficient Balance", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (cryptoAmount < 10) {
      toast.error("Cannot convert amount less than 10!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      setFormError(false);
      show();
      const updatedTransactionData = {
        cryptoAmount: parseFloat(cryptoAmount),
        cryptoCurrency,
        fiatAmount: parseFloat(fiatAmount),
        fiatCurrency,
        conversionRate,
      };

      updateTransactionData(updatedTransactionData);
      // console.log(updatedTransactionData);
      if (isConnected) {
        navigate("/dashboard/recipient");
      }
    }
    setValidated(true);
  };
  return (
    <>
      <div className="container pb-5">
        <div className="transaction-form mx-auto mt-5 mb-5 pb-4">
          <h3 className="transaction-header text-center mt-4">
            Convert Crypto to Fiat
          </h3>

          <Form className="mx-4 mt-5" noValidate validated={validated}>
            <Form.Group className="mb-3">
              <Form.Label className="d-flex justify-content-between m-0">
                <p className="transaction-form-tlight"> You are Sending:</p>
                <p className="transaction-form-tlight">
                  Balance:{" "}
                  {cryptoBalance && crytoDecimal
                    ? (+ethers.utils.formatUnits(
                        cryptoBalance,
                        crytoDecimal
                      )).toFixed(2)
                    : "0.00"}{" "}
                  {cryptoCurrency}
                </p>
              </Form.Label>

              <div className="d-flex">
                <Form.Control
                  type="text"
                  name="cryptoAmount"
                  placeholder="0.00"
                  className="send-input"
                  value={cryptoAmount}
                  onChange={handleCryptoAmountChange}
                  required
                  pattern="\d+(\.\d+)?"
                />
                <Select
                  options={cryptoOptions}
                  className={`crypto-input  ${
                    !cryptoCurrency && formError ? "border-danger" : ""
                  }`}
                  placeholder={cryptoCurrency}
                  name="cryptoCurrency"
                  styles={customStyles}
                  value={cryptoCurrency}
                  onChange={handleCryptoCurrencyChange}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                Incorrect value
              </Form.Control.Feedback>
            </Form.Group>

            {conversionRate && (
              <div className="convert-rate my-4">
                <p className="text-center conversion-rate">
                  Conversion rate: 1 {cryptoCurrency} ={fiatCurrency}{" "}
                  {conversionRate}
                </p>
              </div>
            )}
            <Form.Group className="mb-4">
              <Form.Label className="transaction-form-tlight">
                To Fiat:
              </Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  placeholder="0.00"
                  className="send-input"
                  disabled
                  value={fiatAmount}
                  required
                  name="fiatAmount"
                />
                <Select
                  options={fiatOptions}
                  className={`crypto-input  ${
                    !fiatCurrency && formError ? "border-danger" : ""
                  }`}
                  styles={customStyles}
                  placeholder={fiatCurrency}
                  name="fiatCurrency"
                  value={fiatCurrency}
                  onChange={handleFiatCurrencyChange}
                />
              </div>
            </Form.Group>

            <div className="mt-5">
              <div className="d-flex justify-content-between">
                <p>You are converting</p>
                <p className="convert-summary">
                  {cryptoAmount ? +cryptoAmount - TRANSACTION_FEE : ""}{" "}
                  {cryptoCurrency && cryptoCurrency}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="">To receive</p>
                <p className="convert-summary">
                  {fiatCurrency && fiatCurrency} {fiatAmount}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Fee</p>
                <p className="convert-summary">
                  {TRANSACTION_FEE} {cryptoCurrency && cryptoCurrency}
                </p>
              </div>
            </div>

            <div className="connect-button2 d-grid gap-2 col-11 mx-auto mt-2 justify-content-center">
              <ConnectKitButton.Custom>
                {({ isConnected, show, truncatedAddress, ensName }) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e, show);
                        settoRecipientPage(true);
                        console.log("Connected");
                      }}
                      className="btn connect-button"
                    >
                      {isConnected ? ensName ?? truncatedAddress : "Continue"}
                    </button>
                  );
                }}
              </ConnectKitButton.Custom>
            </div>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConvertComponent;
