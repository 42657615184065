export const supportedTokens = {
  testnet: {
    usdc: "0x875cc710561bED927410A1812323e67719F87D28",
    usdt: "0x01708a8eb0b7705CCDcE9ee45728A712425Af042",
    dai: "0x3F1149Abc87663f2Bb9CF1745C6C507A0B116052",
  },
  mainnet: {
    usdc: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    usdt: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  },
  sepolia: {
    usdc: "0x4aC70D5D1E40EEc6D17737daf70D48845Dd3D397",
    usdt: "0xe5BE316ca37d63124A7321B8f789a92455293E89",
    dai: "0xA00cABF8da0f9fD6522EafA39e04547E71B855fd",
  },
  bsc_testnet: {
    usdc: "0x2e53C48E393d931Be5A9928CF01cC17DB64AdEe1",
    usdt: "0x48C108C47ABD5dd35f69059C85d30F99C7810b23",
    dai: "0xf2b3b596be9eA6bF92506736BaE5B889faF848bD",
  },
};

export const COERAL_CONTRACT_ADDRESS = {
  testnet: "0xfDfca1323f179D50F036F476341D44794121a5bB",
  mainnet: "0xAF4051d6B0c8D32288e4Adb9BEA8a9aE9C9EAAbc",
  bsc_testnet: "0x9D7A0d67Af7bBc18fE8c16f51246D52A597F47b3",
  sepolia: "0x2e53C48E393d931Be5A9928CF01cC17DB64AdEe1",
};
