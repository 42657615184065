import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

const SetupPopup = () => {
  const [showAlert, setShowAlert] = useState(true);
  const handleCloseAlert = () => setShowAlert(false);
  let navigate = useNavigate();

  const goToVerifyAccount = () => {
    navigate("/dashboard/verify-account");
  };

  return (
    <>
      <div className="pop-up mx-auto" onClick={goToVerifyAccount}>
        {showAlert && (
          <Alert
            variant="info"
            dismissible
            onClose={handleCloseAlert}
            className="ps-5"
          >
            <span>
              Complete your account set up
              <i className="bi bi-arrow-right ms-5"></i>
            </span>
          </Alert>
        )}
        {/* <Button onClick={() => setShowAlert(true)}>Sjow</Button> */}
      </div>
    </>
  );
};

export default SetupPopup;
