import React from "react";
import { Button, Modal } from "react-bootstrap";

const VerifyModal = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="verify-modal"
      >
        <Modal.Header className="mt-5 pt-3">
          <Modal.Title id="contained-modal-title-vcenter" className="mx-auto">
            Verification Completed!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-5 text-center">
          You have completed your account verification we will process your
          transaction and notify you through an email.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              window.location.href = "/dashboard/transaction-form";
            }}
            className="mx-3 py-3"
          >
            View Dashboard
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VerifyModal;
