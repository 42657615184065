export const faqQuestions = [
  {
    id: "flush-headingOne",
    collapse: "flush-collapseOne",
    question: "Who are we?",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor culpa excepturi illum! Repellat tempora recusandae eaque quaerat mollitia quasi, rerum magnam, officiacumque nisi, odio illo facere saepe voluptatem. Ad, consequaturab assumenda earum porro enim est voluptates nihil vitae eaque. Nesciunt suscipit cupiditate tempore architecto laborum modi dolores labore",
  },
  {
    id: "flush-headingTwo",
    collapse: "flush-collapseTwo",
    question: "Who are we?",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor culpa excepturi illum! Repellat tempora recusandae eaque quaerat mollitia quasi, rerum magnam, officiacumque nisi, odio illo facere saepe voluptatem. Ad, consequaturab assumenda earum porro enim est voluptates nihil vitae eaque. Nesciunt suscipit cupiditate tempore architecto laborum modi dolores labore",
  },
  {
    id: "flush-headingThree",
    collapse: "flush-collapseThree",
    question: "Who are we?",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor culpa excepturi illum! Repellat tempora recusandae eaque quaerat mollitia quasi, rerum magnam, officiacumque nisi, odio illo facere saepe voluptatem. Ad, consequaturab assumenda earum porro enim est voluptates nihil vitae eaque. Nesciunt suscipit cupiditate tempore architecto laborum modi dolores labore",
  },
  {
    id: "flush-headingFour",
    collapse: "flush-collapseFour",
    question: "Who are we?",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor culpa excepturi illum! Repellat tempora recusandae eaque quaerat mollitia quasi, rerum magnam, officiacumque nisi, odio illo facere saepe voluptatem. Ad, consequaturab assumenda earum porro enim est voluptates nihil vitae eaque. Nesciunt suscipit cupiditate tempore architecto laborum modi dolores labore",
  },
  {
    id: "flush-headingFive",
    collapse: "flush-collapseFive",
    question: "Who are we?",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor culpa excepturi illum! Repellat tempora recusandae eaque quaerat mollitia quasi, rerum magnam, officiacumque nisi, odio illo facere saepe voluptatem. Ad, consequaturab assumenda earum porro enim est voluptates nihil vitae eaque. Nesciunt suscipit cupiditate tempore architecto laborum modi dolores labore",
  },
];
