export const BaseUrl = "https://coeral-backend.herokuapp.com";
// export const BaseUrl = "http://localhost:3000";
export const userRoute = "/users";
export const kycVerifyRoute = "/kycs/upload";
export const countryRoute = "/callingcode";
export const kycType = "/kycType";
export const getByAddress = "/users/address";
export const getBanksbyCountry = "/bank?callingCodeId=2";
export const recipientDetails = "/bankDetail";
export const getRecipientsbyUserId = "bankDetail?userId=";
export const deleteRecipientbyID = "bankDetail?id=";
export const getKycByUserId = "kycs/";
export const getFiatCurrency = "currency/fiat";
export const getCryptoCurrency = "currency/crypto";
export const getCountryCodesByRegion = "/callingcode/region/";
export const resolveAccountName = "/bank/resolve-account-name";
export const postFeedback = "/feedback";
export const kycStatus = {
  1: {
    color: "kycPending",
    display: "Pending",
  },
  2: { color: "kycApproved", display: "Approved" },
  3: { color: "kycRejected", display: "Rejected" },
};

export const supportedFiat = {
  NGN: 1,
  CEDIS: 2,
};
export const getTransaction = "/transaction";

export const TRANSACTION_FEE = 1;

export const REGIONS = ["Africa", "Americas", "Asia", "Europe", "Oceania"];
