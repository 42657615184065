import React, { useEffect, useState } from "react";
import { Container, Form, Table, Modal } from "react-bootstrap";
import { useAccount } from "wagmi";
import { getUserTransactions } from "../../api/transactions";
import {
  addCommasToNumber,
  dateFormater,
  truncate,
} from "../../constants/helpers";

const History = () => {
  const [transactioHistory, setTransactioHistory] = useState([]);
  const { address } = useAccount();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setTransactions();
  }, [address]);

  async function setTransactions() {
    try {
      const trxs = await getUserTransactions(address);
      setTransactioHistory(trxs);
    } catch (err) {
      console.log(err);
    }
  }

  const handleViewDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const filteredTransactions = transactioHistory.filter((transaction) =>
    dateFormater(transaction.created_at).date.includes(searchQuery)
  );

  const renderTransactionDetails = () => {
    if (!selectedTransaction) {
      return null; // Return null if selectedTransaction is null
    }
    const formatedNum = addCommasToNumber(selectedTransaction.receivedAmount);
    const formattedAmount = addCommasToNumber(+selectedTransaction.amount);
    return (
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="transactionHistoryDetails"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="transaction-date">
            {dateFormater(selectedTransaction.created_at).date}{" "}
            {dateFormater(selectedTransaction.created_at).time}
          </p>

          <p className="converted-text mt-5">
            You converted {formattedAmount} {selectedTransaction.fromCurrency}{" "}
            to {selectedTransaction.toCurrency} {formatedNum}
          </p>

          <div className="line"></div>

          <div class="transaction-summary mt-3">
            <div class="row w-100">
              <div class="col-md-6">
                <span> Transaction ID: </span>
                <span class="text-white">
                  {truncate(selectedTransaction.transactionHash)}
                </span>
              </div>
              <div class="col-md-6">
                <span>Conversion Method: </span>
                <span class="text-white">Bank Account</span>
              </div>
            </div>
            <div class="row w-100">
              <div class="col-md-6">
                <span>You transferred: </span>
                <span class="text-white">
                  {formattedAmount} {selectedTransaction.fromCurrency}
                </span>
              </div>
              <div class="col-md-6">
                <span> Receipient Name: </span>
                <span class="text-white">
                  {selectedTransaction.beneficiary}
                </span>
              </div>
            </div>
            <div class="row w-100">
              <div class="col-md-6">
                <span>Recipient Received: </span>
                <span class="text-white">
                  {selectedTransaction.toCurrency} {formatedNum}
                </span>
              </div>
              <div class="col-md-6">
                <span>Recipient Email: </span>
                <span class="text-white"></span>
              </div>
            </div>
            <div class="row w-100">
              <div class="col-md-6">
                <span>Transaction Rate: </span>
                <span class="text-white">
                  1 {selectedTransaction.fromCurrency} ={" "}
                  {selectedTransaction.toCurrency}{" "}
                  {addCommasToNumber(selectedTransaction.rate)}
                </span>
              </div>
              <div class="col-md-6">
                <span> Bank Name: </span>
                <span class="text-white">{selectedTransaction.bank_name}</span>
              </div>
            </div>
            <div class="row w-100">
              <div class="col-md-6">
                <span>Coeral's Fee: </span>
                <span class="text-white">
                  1 {selectedTransaction.fromCurrency}
                </span>
              </div>
              <div class="col-md-6">
                <span> Account Number: </span>
                <span class="text-white">{selectedTransaction.account}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className="row">
            <div className="col d-flex">
              <span>Transaction Status</span>
              <div className="sucess-box">Succesful</div>
            </div>
          </div>
        </Modal.Footer> */}
      </Modal>
    );
  };

  return (
    <>
      <Container className="history d-flex aligns-items-center flex-column mt-5">
        <h3 className="text-white">Transaction history</h3>
        <Form className="d-flex mt-4">
          <Form.Control
            className="search"
            type="search"
            placeholder="Search Transaction by Date"
            aria-label="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="submit"></button>
        </Form>

        <Table borderless responsive className="mt-5 mx-auto">
          {filteredTransactions.length === 0 ? (
            <p className="no-data text-center text-light mt-5">
              No history data available!
            </p>
          ) : (
            filteredTransactions.map((d, i) => (
              <tbody
                onClick={() => handleViewDetails(d)}
                key={i}
                className="table-body"
              >
                <tr className="align-middle">
                  <td className="transaction-date-mobile">
                    {dateFormater(d.created_at).date}
                  </td>
                </tr>
                <tr className="align-middle">
                  <td className="transaction-date">
                    {dateFormater(d.created_at).date}
                  </td>
                  <td className="converted">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="circle-container converted-icon-container me-3">
                        <span className="icon"></span>
                      </div>{" "}
                      <span>Converted</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="circle-container crypto-icon-container me-2">
                        <span
                          className={
                            d.fromCurrency === "USDT"
                              ? "usdt-icon"
                              : "usdc-icon"
                          }
                        ></span>
                      </div>{" "}
                      <span>
                        {addCommasToNumber(d.amount)} {d.fromCurrency}
                      </span>
                    </div>
                  </td>
                  <td className="d-sm-none">Converted</td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="circle-container fiat-icon-container me-2">
                        <span className="icon"></span>
                      </div>{" "}
                      <span>{addCommasToNumber(d.receivedAmount)}</span>
                    </div>
                  </td>

                  <td>
                    <button
                      className=" btn table-btn"
                      onClick={() => handleViewDetails(d)}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              </tbody>
            ))
          )}
        </Table>
      </Container>
      {renderTransactionDetails()}
    </>
  );
};

export default History;
