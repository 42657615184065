import React, { useContext } from "react";
import {
  Container,
  Dropdown,
  Form,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import DarkLogo from "../../images/Logo-Light.png";
import LightLogo from "../../images/Logo-Dark.png";
import { useNetwork, useSwitchNetwork, useDisconnect, useAccount } from "wagmi";
import Emoji from "../Emoji";
import { NavLink, Outlet } from "react-router-dom";
import { ChainIcon } from "connectkit";
import truncateEthAddress from "truncate-eth-address";
import { UserContext } from "../../contexts/UserContext";
import { kycStatus } from "../../constants/constantRoute";

const DashNavBar = () => {
  const { user, kycStatus: _kycStatus } = useContext(UserContext);
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();

  const setKycStatus = () => {
    if (!_kycStatus) {
      return { color: "kycColor", display: "Unverified" };
    }
    return kycStatus[_kycStatus];
  };

  return (
    <>
      <Navbar key={"lg"} expand={"lg"} className="py-4 mx-sm-5">
        <Container>
          <Navbar.Brand href="/dashboard/transaction-form">
            <img src={DarkLogo} alt="logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-xl`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {" "}
              <Nav className="dash-nav flex-grow-1 mx-5 px-5">
                <NavLink
                  exact
                  to="/dashboard/transaction-form"
                  activeClassName="activeClicked"
                  className="link"
                >
                  Dashboard
                </NavLink>
                <NavLink
                  exact
                  to="/dashboard/recipients"
                  activeClassName="activeClicked"
                  className="link"
                >
                  Recipient
                </NavLink>
                <NavLink
                  exact
                  to="/dashboard/transaction-history"
                  activeClassName="activeClicked"
                  className="link"
                >
                  History
                </NavLink>
              </Nav>
              <div className="network d-flex">
                <Form.Label column className="network-label text-end me-3">
                  Network:
                </Form.Label>

                {/* <div className="network-box d-flex justify-content-center align-items-center me-3 px-3">
                  <ChainIcon id={chain?.id} unsupported={chain?.unsupported} />
                  <p className="">{chain.name}</p>
                </div> */}
                <Nav.Item>
                  <Dropdown className=" chain-box me-4">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className=" d-flex justify-content-center align-items-center">
                        <ChainIcon
                          id={chain?.id}
                          unsupported={chain?.unsupported}
                        />
                        <p className="ps-2 pe-2">{chain ? chain.name : ""}</p>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="drop-menu">
                      <div className="d-flex flex-column">
                        {chains.map((x) => (
                          <div className=" d-flex  align-items-center px-2 mb-3">
                            <ChainIcon
                              id={x?.id}
                              unsupported={chain?.unsupported}
                            />
                            <button
                              disabled={!switchNetwork || x.id === chain?.id}
                              key={x.id}
                              onClick={() => switchNetwork?.(x.id)}
                              className="chain ms-2"
                            >
                              {x.name}
                              {/* {isLoading &&
                              pendingChainId === x.id &&
                              " (switching)"} */}
                            </button>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              </div>
              {/* <Nav.Item className="nav-wallet">
                <Dropdown as={ButtonGroup}>
                  <ConnectKitButton.Custom>
                    {({ show, truncatedAddress }) => {
                      return (
                        <button onClick={show} className="btn walllet-btn">
                          {truncatedAddress}
                          <Emoji symbol=" 😎" />
                        </button>
                      );
                    }}
                  </ConnectKitButton.Custom>
                  <Dropdown.Toggle split className="split-btn ">
                    <Dropdown.Menu>
                      <Dropdown.Item>Action</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown.Toggle>
                </Dropdown>
              </Nav.Item> */}
              <Nav.Item>
                <Dropdown className="wallet-btn">
                  <Dropdown.Toggle className="d-flex justify-content-center align-items-center">
                    <p className="address mt-3 me-3">
                      {" "}
                      {user ? truncateEthAddress(user) : ""}
                    </p>

                    <Emoji symbol=" 😎" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="account d-flex justify-content-between align-items-center"
                      disabled
                    >
                      <p>Account</p>
                      <p className={`verify-status ${setKycStatus().color}`}>
                        {setKycStatus().display}
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item className="disconnect">
                      <button
                        className="btn btn-primary d-flex justify-content-center align-items-center"
                        onClick={() => disconnect()}
                      >
                        Disconnect Wallet
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>
              <Nav.Item className="wallet-btn-2 mt-5 px-4 pt-4">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="address mt-3 me-3">
                    {" "}
                    {user ? truncateEthAddress(user) : ""}
                  </p>

                  <Emoji symbol=" 😎" />
                </div>
                <div className="account d-flex justify-content-between">
                  <p>Account</p>
                  <p className={`verify-status ${setKycStatus().color}`}>
                    {setKycStatus().display}
                  </p>
                </div>

                <button
                  className="btn btn-primary d-flex justify-content-center align-items-center"
                  onClick={() => disconnect()}
                >
                  Disconnect Wallet
                </button>
              </Nav.Item>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <Outlet />
      {/* ))} */}
    </>
  );
};

export default DashNavBar;
