import React from "react";
import DashNavBar from "../Components/Dashboard/DashNavBar";
import "../css/dashboard.css";
import { useAccount } from "wagmi";
import { useState, useEffect } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { BaseUrl, postFeedback } from "../constants/constantRoute";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [feedbackForm, setFeedbackForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setFeedbackForm(false);

      try {
        await axios.post(`${BaseUrl}${postFeedback}`, {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        });
        console.log("Feedback Data Data:", formData);
        toast.success("Feedback submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        form.reset();
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } catch (error) {
        console.error("Erorr", error);
        // Show error toast
        toast.error("Failed to submit feedback. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const { isConnected } = useAccount();
  let navigate = useNavigate();
  const checkConnected = () => {
    if (isConnected) {
    } else {
      console.log("Disconnected");
      navigate("/");
    }
  };

  // const [darkMode, setDarkMode] = useState(
  //   localStorage.getItem("theme") === "dark"
  // );

  // useEffect(() => {
  //   localStorage.setItem("theme", darkMode ? "dark" : "light");
  // }, [darkMode]);

  return (
    <>
      {checkConnected()}
      {loading ? (
        <HashLoader
          color={"#009d77"}
          loading={loading}
          cssOverride
          size={150}
          className="loader"
        />
      ) : (
        <div className="dashboard">
          <div className="feedback">
            <button
              className="feedback-btn btn"
              onClick={() => setFeedbackForm(true)}
            >
              Feedback
            </button>
            <button
              className="feedback-btn2 btn"
              onClick={() => setFeedbackForm(true)}
            >
              <i class="bi bi-question-circle"></i>
            </button>

            <Modal
              show={feedbackForm}
              onHide={() => setFeedbackForm(false)}
              className="feedback-form"
              size="md"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-center mx-auto mt-3">
                  We'd love to hear from you!
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      className="text-black"
                      type="text"
                      placeholder="John Doe"
                      required
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid name.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="johndoe@gmail.com"
                      required
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message..."
                      required
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid message.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* <button className="btn btn-primary mx-auto d-flex justify-content-center align-items-center">
                    Submit Feedback
                  </button> */}
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      className="feedback-submit-btn"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
          {/* <div
            className="dark-light ms-4"
            onClick={() => setDarkMode(!darkMode)}
          >
            <i className={darkMode ? "bi bi-sun-fill" : "bi bi-moon-fill"}></i>
          </div> */}
          <DashNavBar />
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Dashboard;
