import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { getUserRecipients, deleteRecipient } from "../../api/recipients";

const Recipients = () => {
  const { userId } = useContext(UserContext);
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState([]);
  const [showModal, setModalShow] = useState(false);
  const [deleteRecipientId, setDeleteRecipientId] = useState(null);

  useEffect(() => {
    _setRecipients();
  }, [userId]);

  const handleDelete = async (id) => {
    setDeleteRecipientId(id); // // Store the recipient ID in state
    setModalShow(true); // Open the modal when delete is clicked
    console.log(deleteRecipientId);
  };

  const confirmDelete = async () => {
    try {
      const succeed = await deleteRecipient(deleteRecipientId);
      if (succeed) {
        window.location.reload();
        navigate("/dashboard/recipients");
        return;
      }
    } catch (error) {
      alert(error.message);
    }
  };
  async function _setRecipients() {
    try {
      const _recipients = await getUserRecipients(userId);
      setRecipient(_recipients);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center flex-column mb-5">
          <Col lg={6}>
            <div className="recipients mt-5 pb-5">
              <div className="recipients-add d-flex justify-content-between align-items-center">
                <span className="text-white">Recipients</span>
                <button
                  className="add-btn btn"
                  onClick={() => {
                    navigate("/dashboard/add-recipient");
                  }}
                >
                  Add Recipient
                </button>
              </div>
              {recipient.length === 0 ? (
                <p className="no-data text-center text-light mt-5">
                  No recipients data available!
                </p>
              ) : (
                recipient.map((d, i) => (
                  <div key={i} className="recipients-item-container">
                    <div className="recipients-item mt-5 pt-3 pb-1 px-3">
                      <div className="d-flex justify-content-between">
                        <p>Name: </p>
                        <p>{d.fullName}</p>
                      </div>
                      {/* <div className="d-flex justify-content-between">
                      <p>Email: </p>
                    </div> */}
                      <div className="d-flex justify-content-between">
                        <p>Bank Name: </p>
                        <p>{d.bank.fullName}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>Account Number: </p>
                        <p>{d.accountNumber}</p>
                      </div>
                      {d.email && (
                        <div className="d-flex justify-content-between">
                          <p>Email: </p>
                          <p>{d.email}</p>
                        </div>
                      )}
                    </div>
                    <div className="d-flex w-100">
                      <Link
                        to={`/dashboard/edit-recipient/${d.id}`}
                        className="recipient-item-btn btn"
                      >
                        Edit
                      </Link>
                      <button
                        className="recipient-item-btn btn"
                        onClick={() => handleDelete(d.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        show={showModal}
        onHide={() => setModalShow(false)}
        centered
        className="confirmDeleteModal"
        backdrop="static"
        keyboard={false}
      >
        {/* <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}

        <Modal.Body className="mt-3">
          <p className="text-center">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="delete-btn" onClick={() => confirmDelete()}>
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            No
          </Button>
        </Modal.Footer>

        {/* <button onClick={() => confirmDelete}>Yes</button>
        <button onClick={() => setModalShow(false)}>No</button> */}
      </Modal>
    </>
  );
};

export default Recipients;
