import React from "react";
import { faqQuestions } from "../../js/faqData";
export const Faq = () => {
  return (
    <section className="faq d-flex flex-column justify-content-center align-items-center">
      <div className="container contain">
        <h5 className="text-center mb-5">Frequently asked questions</h5>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {faqQuestions.map((item) => {
            return (
              <div id="accordion" className="accordion-item">
                <h2 className="accordion-header" id={item.id}>
                  <button
                    className="check accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#` + item.collapse}
                    aria-expanded="false"
                    aria-controls={item.collapse}
                  >
                    {item.question}
                  </button>
                </h2>
                <div
                  id={item.collapse}
                  className="accordion-collapse collapse"
                  aria-labelledby={item.id}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
