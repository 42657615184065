import { createContext, useState } from "react";

export const TransactionFormContext = createContext();

const TransactionFormProvider = ({ children }) => {
  const [transactionData, setTransactionData] = useState({
    cryptoAmount: "",
    cryptoCurrency: "",
    fiatAmount: "",
    fiatCurrency: "",
    conversionRate: "",
  });

  const updateTransactionData = (updatedData) => {
    setTransactionData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };
  return (
    <TransactionFormContext.Provider
      value={{ transactionData, updateTransactionData }}
    >
      {children}
    </TransactionFormContext.Provider>
  );
};

export default TransactionFormProvider;
