import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import DarkLogo from "../../images/Logo-Light.png";
import LightLogo from "../../images/Logo-Dark.png";
import { ConnectKitButton } from "connectkit";

export const Navigation = ({ darkMode, setDarkMode }) => {
  return (
    <>
      <Navbar className="py-4 mx-sm-2">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={DarkLogo}
              alt="logo"
              className="logo"
            />{" "}
          </Navbar.Brand>
          {/* <Navbar.Brand href="/">
            <p className="logo">coeral</p>
          </Navbar.Brand> */}
          <Nav className="d-flex justify-content-end">
            <Nav.Item>
              <ConnectKitButton.Custom>
                {({ isConnected, show, truncatedAddress, ensName }) => {
                  return (
                    <button onClick={show} className="btn connect-button">
                      {isConnected
                        ? ensName ?? truncatedAddress
                        : "Connect Wallet"}
                    </button>
                  );
                }}
              </ConnectKitButton.Custom>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};
