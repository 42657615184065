import axios from "axios";
import { BaseUrl, getCountryCodesByRegion } from "../constants/constantRoute";

export async function getCountryCodes(region) {
  try {
    if (!region) return [];
    const res = await axios.get(`${BaseUrl}${getCountryCodesByRegion}${region}`);
    if (!res.data) return [];
    return [...res.data];
  } catch (error) {
    console.log(error);
      return []

  }
}